// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-form-js": () => import("./../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-20200703-js": () => import("./../src/pages/index-20200703.js" /* webpackChunkName: "component---src-pages-index-20200703-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-july-2-js": () => import("./../src/pages/july2.js" /* webpackChunkName: "component---src-pages-july-2-js" */),
  "component---src-pages-kotan-js": () => import("./../src/pages/kotan.js" /* webpackChunkName: "component---src-pages-kotan-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-qanda-730-js": () => import("./../src/pages/qanda730.js" /* webpackChunkName: "component---src-pages-qanda-730-js" */),
  "component---src-pages-qanda-js": () => import("./../src/pages/qanda.js" /* webpackChunkName: "component---src-pages-qanda-js" */),
  "component---src-pages-registration-for-business-js": () => import("./../src/pages/registration-for-business.js" /* webpackChunkName: "component---src-pages-registration-for-business-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

